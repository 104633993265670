import React, { useEffect, useState } from "react";
import { DIMENSIONS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import { useNavigate } from "react-router-dom";
import InvestorsPage from "../common/InvestorsPage";
import ToolboxPage from "./ToolboxPage";
import AccountPage from "../common/AccountPage";
import ArchivePage from "./ArchivePage";
import NavIconButton from "../../../components/NavIconButton";
import AppDropdown from "../../../components/AppDropdown";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import { saveSession } from "../../../utils/sessionHelper";

import "../../Home.scss";
import "../../Pages.scss";
import "../../Datatables.scss";
import Tag from "../../../components/Tag";
import MenuTitle from "../../../components/MenuTitleREFACTO";
import { Button } from "primereact/button";

function IntermediateRouter() {
  const { getAuthorizedScopeList } = useAuthorizationsContext();
  const {
    navigationContext,
    changeSubapp,
    setExpandedMenu,
    showBugReport,
    showReleaseNote,
    isPreproduction,
  } = useNavigationContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    saveSession(null);
    navigate("/");
  };

  const unauthorizedDisplay = (
    <div className="app-container">
      <div className="unauthorized-page bg-custom-violet">
        {
          "Votre compte existe mais ne possède pas les autorisations suffisantes pour utiliser cet outil. Merci de contacter les administrateurs."
        }
      </div>
    </div>
  );

  const subappMenu = () => (
    <div className="navbar-container">
      <div className="navbar-menu-container">
        <MenuTitle
          label={""}
          img={MEDIAS.logoCrescendo}
          showLabel={navigationContext.expandedMenu}
        />
        <div className="navbar-submenu-container">
          <NavIconButton
            key={"investor"}
            index={"investor"}
            text={"Investisseurs"}
            src={MEDIAS.thirdParty}
            onClick={() => changeSubapp("investor")}
            isActive={navigationContext.currentSubapp === "investor"}
            showLabel={navigationContext.expandedMenu}
          />
          <NavIconButton
            key={"archive"}
            index={"archive"}
            text={"Historique"}
            src={MEDIAS.archive}
            onClick={() => changeSubapp("archive")}
            isActive={navigationContext.currentSubapp === "archive"}
            showLabel={navigationContext.expandedMenu}
          />
          <NavIconButton
            key={"toolbox"}
            index={"toolbox"}
            text={"Boîte à outils"}
            src={MEDIAS.toolbox}
            onClick={() => changeSubapp("toolbox")}
            isActive={navigationContext.currentSubapp === "toolbox"}
            showLabel={navigationContext.expandedMenu}
          />
        </div>

        <div className="navbar-submenu-container">
          {navigationContext.expandedMenu ? (
            <div className="navbar-submenu-label">Paramètres</div>
          ) : (
            <div className="navbar-submenu-separator" />
          )}
          <NavIconButton
            key={"account"}
            index={"account"}
            text={"Mon compte"}
            src={MEDIAS.account}
            onClick={() => changeSubapp("account")}
            isActive={navigationContext.currentSubapp === "account"}
            showLabel={navigationContext.expandedMenu}
          />
        </div>
      </div>
    </div>
  );

  const subapp = (
    <>
      <InvestorsPage hidden={navigationContext.currentSubapp !== "investor"} />
      <ToolboxPage hidden={navigationContext.currentSubapp !== "toolbox"} />
      <AccountPage hidden={navigationContext.currentSubapp !== "account"} />
      <ArchivePage hidden={navigationContext.currentSubapp !== "archive"} />
    </>
  );

  // Account has no authorizations
  if (!getAuthorizedScopeList().length) return unauthorizedDisplay;

  return (
    <div className="app-container">
      <div
        className="navbar-vertical-container"
        style={{
          maxWidth: navigationContext.expandedMenu
            ? DIMENSIONS.navBarWidthOpen
            : DIMENSIONS.navBarWidthClose,
        }}
      >
        <div className="navbar-container">
          {subappMenu()}
          <div className="bottom-container">
            {isPreproduction && (
              <Tag value={"Env. démo"} bgColor={"bg-orange-700 mb-2"} />
            )}
            <Button
              icon={`pi ${navigationContext.expandedMenu ? "pi-angle-double-left" : "pi-angle-double-right"}`}
              text
              size="small"
              rounded
              className="text-white opacity-50 hover:opacity-100 active:opacity-100"
              onClick={() => setExpandedMenu(!navigationContext.expandedMenu)}
            />
            {navigationContext.expandedMenu ? (
              <div onClick={showBugReport} className="version">
                {`Un bug ?`}
              </div>
            ) : (
              <NavIconButton
                key={"bug-report"}
                index={"bug-report"}
                src={MEDIAS.bug}
                onClick={showBugReport}
                isActive={false}
              />
            )}
            {navigationContext.expandedMenu ? (
              <div onClick={handleLogout} className="version">
                {`Se déconnecter`}
              </div>
            ) : (
              <NavIconButton
                key={"logout"}
                index={"logout"}
                src={MEDIAS.logout}
                onClick={handleLogout}
                isActive={false}
              />
            )}

            <div className="version" onClick={showReleaseNote}>
              {navigationContext.expandedMenu
                ? `Version ${CONSTANTS.VERSION}`
                : `${CONSTANTS.VERSION}`}
            </div>
          </div>
        </div>
      </div>
      {/* Page content */}
      <div className="w-full overflow-x-scroll overflow-y-hidden">{subapp}</div>
    </div>
  );
}

export default IntermediateRouter;
