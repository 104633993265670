import React from "react";

function BreadCrumb({ items }) {
  return (
    <div className="h-16 bg-gray-900 w-full flex flex-row justify-between items-center px-5 py-3">
      {/* Items */}
      <div className="flex flex-row gap-x-5">
        {items.map((item, index) => (
          <div
            key={index}
            className={`flex flex-row items-center gap-x-5 cursor-pointer`}
            onClick={item.onClick}
          >
            <span
              className={`transition-colors text-2xl font-medium ${item.isActive ? "text-gray-50" : "text-gray-500 hover:text-gray-300"}`}
            >
              {item.label}
            </span>
            {index !== items?.length - 1 && (
              <span className="pi pi-arrow-right text-gray-400 !text-sm"></span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default BreadCrumb;
